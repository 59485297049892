import { Card, Col } from "antd";
import { FC } from "react";
import "./SourceCard.css";

const selectedCardStyle: React.CSSProperties = {
  boxShadow: "0px 6px 18px #00000029",
  border: " 5px solid rgb(0, 84, 207)",
  borderRadius: "12px",
};

export const SourceCard: FC<{
  title: string;
  selected?: boolean;
  description?: string;
  onClick: Function;
}> = ({ title, selected, description, onClick }) => {
  return (
    <Col xs={{ span: 6 }}>
      <Card
        className="source-card"
        style={selected ? selectedCardStyle : {}}
        styles={{
          body: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            cursor: "pointer",
            userSelect: "none",
          },
        }}
        onClick={() => onClick()}
      >
        <h2>{title}</h2>
        {description}
      </Card>
    </Col>
  );
};
