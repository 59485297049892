import React, { useState, ChangeEvent, useEffect } from "react";
import { EditOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { Modal, Button, Row, Col, Typography, Select, Slider, Input, Tag, Tooltip } from "antd";
import { LeftOutlined, UserOutlined, GlobalOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { BsFillMicFill, BsSendFill, BsPaperclip, BsGearFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import ImageGallery from "./ImageGallery";
import "./ImageModal.css";
import OrignalImage from "../assets/Original.webp";
import Parsona from "../assets/Persona.jpg";
import VVPM from "../component/Tags/VVPM";
import Data from "./mockData";
import { useAppSelector } from "../../store/hooks";

const { Title, Text } = Typography;

interface ImageModalProps {
  open: boolean;
  setOpen: Function;
  // isVisible: boolean;
  // onClose: () => void;
  // onSave: () => void;
  // modalOpen: boolean;
  // setModalOpen: Function; //(value: boolean) => void;
}

const RightSideContent: React.FC<{
  setIsTyped: Function;
  setIsloder: Function;
}> = ({ setIsTyped, setIsloder }) => {
  const [input, setInput] = useState("");
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  return (
    <div style={{ padding: "0 16px" }}>
      <Title level={5} style={{ marginBottom: 16 }}>
        General Settings
      </Title>

      <Text strong>Aspect Ratio</Text>
      <Select
        defaultValue="square"
        style={{ width: "100%", marginBottom: 16 }}
        suffixIcon={<span style={{ color: "#6752e9" }}>▼</span>}
      >
        <Select.Option value="square">Rectangle (4:3)</Select.Option>
      </Select>

      <Text strong>Contrast & Exposure</Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid #d9d9d9",
          borderRadius: 8,
          padding: "8px 12px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          backgroundColor: "#ffffff",
        }}
      >
        <BsGearFill style={{ color: "#000000", marginRight: 8 }} />
        <Slider
          defaultValue={30}
          style={{
            flex: 1,
            margin: "2px",
          }}
          trackStyle={{ backgroundColor: "#6752e9" }}
          handleStyle={{ borderColor: "#6752e9" }}
        />
        <BsGearFill style={{ color: "#000000", marginLeft: 8 }} />
      </div>

      <div>
        <Text strong style={{ display: "block", marginBottom: 8 }}>
          Output Presences
        </Text>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "8px 12px",
            borderRadius: 8,
            marginBottom: 8,
            color: "#666",
          }}
        >
          <UserOutlined style={{ marginRight: 8 }} />
          <span>Target Persona: The Evidence-Based Phy...</span>
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f5f5f5",
            padding: "8px 12px",
            borderRadius: 8,
            marginBottom: 8,
            color: "#666",
          }}
        >
          <GlobalOutlined style={{ marginRight: 8 }} />
          <span>Channel: Emailer</span>
        </div>
      </div>

      <div>
        <Text strong style={{ display: "block", marginBottom: 8 }}>
          Prompts
        </Text>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            borderRadius: 8,
            padding: 2,
          }}
        >
          {[
            "Cheerful elderly man running energetically in a park.",
            "An elderly man enjoying a walk with a big smile.",
            "smiling old man running with enthusiasm.",
          ].map((tag, index) => (
            <Tag
              key={index}
              style={{
                marginRight: 8,
                marginBottom: 8,
                borderRadius: 16,
                padding: "4px 12px",
                border: "1px solid #6752e9",
                backgroundColor: "white",
                color: "#666",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div>
      </div>

      {/* <Text type="secondary" style={{ fontSize: 12 }}>
        Additional cost accrued for iterations
        <Text style={{ color: "#52c41a", marginLeft: 8, fontSize: 12 }}>$02/-</Text>
        <Tooltip title="Info">
          <InfoCircleOutlined style={{ marginLeft: 4, fontSize: 12, color: "#8c8c8c" }} />
        </Tooltip>
      </Text> */}

      <div style={{ marginTop: 16, position: "relative" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #d9d9d9",
            borderRadius: 8,
            padding: "8px 12px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <BsPaperclip style={{ color: "#bfbfbf", marginRight: 8, cursor: "pointer" }} />
          <input
            placeholder="Enter your prompt here"
            style={{
              flex: 1,
              border: "none",
              outline: "none",
            }}
            value={input}
            onChange={handleInputChange}
          />
          <BsFillMicFill style={{ color: "#bfbfbf", marginRight: 8, cursor: "pointer" }} />
          <BsSendFill
            style={{ color: "#6752e9", cursor: "pointer" }}
            onClick={() => {
              if (input === "") return;
              setInput("");
              setIsTyped(true);
              setIsloder(true);
            }}
          />
        </div>
        <Text
          style={{ position: "absolute", bottom: -20, left: 0, fontSize: 12, color: "#bfbfbf" }}
        >
          Description
        </Text>
      </div>
    </div>
  );
};

const imageStyle: React.CSSProperties = {
  width: "100%",
  height: "auto",
  objectFit: "cover",
  borderRadius: "8px",
};

const iconStyle: React.CSSProperties = {
  color: "black",
  fontSize: "20px",
  margin: "0 6px",
};

const imageContainerStyle: React.CSSProperties = {
  position: "relative",
  overflow: "hidden",
  borderRadius: "8px",
  cursor: "pointer",
};
const tagStyle: React.CSSProperties = {
  backgroundColor: "#EDE9FB",
  color: "#5B21B6",
  borderRadius: "12px",
  padding: "0 8px",
  fontSize: "14px",
  display: "inline-block",
  marginRight: "8px",
};

const matchRateStyle: React.CSSProperties = {
  color: "#16A34A",
  fontSize: "16px",
  fontWeight: "bold",
  display: "inline-block",
  marginLeft: "4px",
};

const matchRateTextStyle: React.CSSProperties = {
  fontSize: "14px",
  color: "#6B7280",
  marginLeft: "4px",
};

export default function ImageModal({
  open,
  setOpen,
  // onClose,
  // onSave,
  // modalOpen,
  // setModalOpen,
}: ImageModalProps) {
  console.log("data", { Data });
  const { selectedAssetParameters } = useAppSelector(state => state.contentReplicator);
  const [data, setData] = useState(Data);
  const [selectedBrand, setSelectedBrand] = useState(
    selectedAssetParameters["brand"] || "Stubixyn",
  );
  const [isTyped, setIsTyped] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  useEffect(() => {
    setSelectedBrand(selectedAssetParameters["brand"]);
  }, [selectedAssetParameters]);

  const onClose = () => {
    setOpen(false);
    setOpenEditModal(false);
    setSelectedImage("");
    setIsTyped(false);
  };
  const onSave = () => {
    setOpen(false);
  };

  const [showModalLeft, setShowModalLeft] = useState(true);

  const metaData = {
    gender: "Male",
    age: "55",
    country: "Spain",
  };
  const aiGeneratedMetaData = {
    gender: "Male",
    age: "55",
    AiGenerated: "Yes",
  };

  const [showModalRight, setShowModalRight] = useState(true);
  const [loadingLoder, setLoadingLoder] = useState(false);

  useEffect(() => {
    if (loadingLoder) {
      setTimeout(() => {
        setLoadingLoder(false);
      }, 3000);
    }
  }, [loadingLoder]);
  const closeEditModal = () => {
    setOpenEditModal(false);
    setSelectedImage("");
    setIsTyped(false);
    //"[1,2]"
  };
  const [selectedImage, setSelectedImage] = useState("");
  console.log("selectedImage", selectedImage);

  return (
    <Modal
      className="image-modal"
      title="Content Creation: Components Contents"
      open={open}
      onCancel={openEditModal ? closeEditModal : onClose}
      footer={[
        <Button key="cancel" onClick={openEditModal ? closeEditModal : onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={openEditModal ? closeEditModal : onClose}>
          Save
        </Button>,
      ]}
      width={1100}
      centered
    >
      {openEditModal ? (
        <>
          <Row gutter={24}>
            <Col span={8}>
              <div
                style={{
                  background: "#f0f2f5",
                  padding: 16,
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Title level={5}>Original Image</Title>

                <div
                  style={{
                    position: "relative",
                    // marginTop: 50,
                    display: "flex",
                    height: "100%",
                    marginTop: isTyped ? "0" : "14px",
                    marginBottom: isTyped ? "51px" : "0",
                  }}
                >
                  <img
                    src={data[selectedBrand]?.originalImage?.url}
                    alt="Main Image"
                    style={{
                      width: "100%",
                      // height: "500px",
                      objectFit: "contain",
                      borderRadius: 8,
                      // marginBottom: 8,
                    }}
                  />
                  <Text
                    style={{
                      position: "absolute",
                      bottom: isTyped ? "63px" : "81px",
                      right: isTyped ? "6px" : "4px",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      padding: "2px 4px",
                      borderRadius: 4,
                      fontSize: 12,
                    }}
                  >
                    PromoMats ID:<span style={{ color: "white" }}>{"CP-001212"}</span>
                  </Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                  <Button type="link" style={{ padding: 0, color: "#6752e9" }}>
                    Show Details
                  </Button>
                </div>
              </div>
            </Col>
            <ImageGallery
              isTyped={isTyped}
              setOpenEditModal={setOpenEditModal}
              Isloder={loadingLoder}
              selectedImage={selectedImage}
              byDefaultSelectedImage={data[selectedBrand]?.equivalentImage?.url}
              setSelectedImage={setSelectedImage}
              setIsTyped={setIsTyped}
            />
            <Col span={8}>
              <RightSideContent setIsTyped={setIsTyped} setIsloder={setLoadingLoder} />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ background: "#f0f2f5", padding: 16, borderRadius: 8 }}>
                <Text
                  style={{
                    fontSize: "16px",
                    color: "black",
                    marginLeft: "4px",
                    fontWeight: 600,
                  }}
                >
                  Original Image{" "}
                  <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                    {/* (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>) */}
                  </span>
                </Text>
                <div style={{ position: "relative" }}>
                  <img
                    src={data[selectedBrand]?.originalImage?.url}
                    alt="Main Image"
                    style={{
                      width: "100%",
                      // height: "500px",
                      objectFit: "contain",
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  />

                  <Text
                    // style={{
                    //   position: "absolute",
                    //   bottom: "218px",
                    //   right: "32px",
                    //   backgroundColor: "rgba(0,0,0,0.5)",
                    //   color: "white",
                    //   padding: "2px 4px",
                    //   borderRadius: 4,
                    //   fontSize: 12,
                    // }}
                    style={{
                      position: "absolute",
                      bottom: "18px",
                      right: "10px",
                      backgroundColor: "rgba(0,0,0,0.5)",
                      color: "white",
                      padding: "2px 4px",
                      borderRadius: 4,
                      fontSize: 12,
                    }}
                  >
                    PromoMats ID:
                    <span style={{ color: "white" }}>
                      {data[selectedBrand]?.originalImage?.promoId}
                    </span>
                  </Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "baseline" }}>
                  {showModalLeft ? (
                    <Button
                      type="link"
                      style={{ padding: 0, color: "#6752e9" }}
                      onClick={() => setShowModalLeft(false)}
                    >
                      Show Details
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        columnGap: "1rem",
                        justifyContent: "space-around",
                        backgroundColor: "white",
                        padding: "8px",
                        borderRadius: "8px",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {/* Close Icon */}
                      <AiOutlineClose
                        onClick={() => setShowModalLeft(true)}
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "1px",
                          cursor: "pointer",
                          fontSize: "18px",
                          color: "#6B7280",
                          transition: "color 0.2s ease",
                        }}
                      />
                      {Object.entries(metaData).map(([key, value]) => (
                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ color: "#6B7280" }}>{key} :</Text>
                          <Text style={{ color: "#000000", marginLeft: 8 }}>{value}</Text>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ padding: 16, borderRadius: 8 }}>
                <Text
                  style={{
                    fontSize: "16px",
                    color: "black",
                    marginLeft: "4px",
                    fontWeight: 600,
                  }}
                >
                  Equivalent Image{" "}
                  <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                    (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
                  </span>
                </Text>
                {/* {console.log("data[selectedBrand]?.equivalentImage", data)} */}
                <div style={imageContainerStyle} className="image-container">
                  <div>
                    <div style={{ position: "relative" }}>
                      <div>
                        <img
                          src={data[selectedBrand as keyof typeof data]?.equivalentImage?.url}
                          alt="Main Image"
                          // style={imageStyle}
                          style={{
                            width: "100%",
                            // height: "500px",
                            objectFit: "contain",
                            borderRadius: 8,
                            marginBottom: 8,
                          }}
                        />
                        <div className="overlay"></div>
                      </div>
                      <Text
                        style={{
                          position: "absolute",
                          bottom: "18px",
                          right: "10px",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "2px 4px",
                          borderRadius: 4,
                          fontSize: 12,
                        }}
                      >
                        PromoMats ID:
                        <span style={{ color: "white" }}>
                          {data[selectedBrand]?.equivalentImage?.promoId}
                        </span>
                      </Text>
                    </div>
                    <div className="icon-container">
                      <LikeOutlined style={iconStyle} />
                      <DislikeOutlined style={iconStyle} />
                    </div>
                    <div className="icon-container2">
                      <EditOutlined style={iconStyle} onClick={() => setOpenEditModal(true)} />
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <Tag style={tagStyle}>AI Generated</Tag>
                     */}
                    <VVPM />
                    <Text style={matchRateStyle}>90%</Text>
                    <Text style={matchRateTextStyle}>Match Rate</Text>
                  </div>

                  {showModalRight ? (
                    <Button
                      type="link"
                      style={{ padding: 0, color: "#6752e9" }}
                      onClick={() => setShowModalRight(false)}
                    >
                      Show Details
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        columnGap: "1rem",
                        justifyContent: "space-around",
                        backgroundColor: "#F0F2F5",
                        padding: "8px",
                        borderRadius: "8px",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {/* Close Icon */}
                      <AiOutlineClose
                        onClick={() => setShowModalRight(true)}
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "1px",
                          cursor: "pointer",
                          fontSize: "18px",
                          color: "#6B7280",
                          transition: "color 0.2s ease",
                        }}
                      />
                      {Object.entries(aiGeneratedMetaData).map(([key, value]) => (
                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ color: "#6B7280" }}>{key} :</Text>
                          <Text style={{ color: "#000000", marginLeft: 8 }}>{value}</Text>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
}
