import PainCureOrignalImage from "../assets/Original.webp";
import PainCureParsona from "../assets/Persona.jpg";
import PrevivaPersona from "../assets/previva/translated.jpg";
import PrevivaOriginalImage from "../assets/previva/source - input.jpg";
import StubixynOrignalImage from "../assets/paincure/Picture of a Person 1.jpg";
import StubixynParsona from "../assets/paincure/localization 1.jpg";
const mockData = {
  PainCure: {
    originalImage: { url: StubixynOrignalImage, promoId: "CP-002025" },
    equivalentImage: { url: StubixynParsona, promoId: "CP-002025" },
  },
  Stubixyn: {
    originalImage: { url: PainCureOrignalImage, promoId: "CP-002025" },
    equivalentImage: { url: PainCureParsona, promoId: "CP-002025" },
  },
  PREVIVA: {
    originalImage: { url: PrevivaOriginalImage, promoId: "CP-002025" },
    equivalentImage: { url: PrevivaPersona, promoId: "CP-002025" },
  },
  ZIVEX: {
    originalImage: { url: PrevivaOriginalImage, promoId: "CP-002025" },
    equivalentImage: { url: PrevivaPersona, promoId: "CP-002025" },
  },
};

export default mockData;
