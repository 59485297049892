import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { InitialScreen } from "./InitialScreen";
import "./InitialSelectionModal.css";

export const InitialSelectionModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const [isModalOpen, setIsModalOpen] = useState(open);

  useEffect(() => {
    setIsModalOpen(open);
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      className="fullscreen-modal initial-selection-modal"
      open={isModalOpen}
      style={{
        backgroundImage:
          "linear-gradient(to right top, rgb(193, 244, 255), white 30%, white 70%, rgb(193, 244, 255))",
      }}
      onCancel={handleCancel}
      bodyProps={{
        style: {
          overflowX: "hidden",
          padding: "20px",
        },
      }}
      footer={null}
    >
      <InitialScreen setModalOpen={setOpen} />
    </Modal>
  );
};
