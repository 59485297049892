import React from "react";
import { Tooltip } from "antd";

export const SelectFieldsWrapper: React.FC<{
  fieldName: string;
  icon?: React.ReactNode;
  children: React.ReactElement;
}> = ({ icon, children, fieldName }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #d9d9d9",
        borderRadius: "4px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Tooltip title={fieldName}>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 8px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {icon && <span style={{ marginRight: "4px", flexShrink: 0 }}>{icon}</span>}
          <span style={{ fontWeight: "500" }}>
            {fieldName}
            {/* {fieldName === "Market" && <span style={{ color: "red" }}>*</span>} */}:
          </span>
        </span>
      </Tooltip>
      <div style={{ flexGrow: 1, minWidth: 0 }}>{children}</div>
    </div>
  );
};
