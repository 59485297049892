import { useState } from "react";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import { ClaimIdentificationLocalisation } from "../../../../ma-dev/Localisation/ClaimIdentificationLocalisation";
import { NonClaimIdentificationLocalisation } from "../../../../ma-dev/Localisation/NonClaimIdentification";
import ImageModal from "../../../../ss-dev/slide35/ImageModal";
import { Button } from "antd";
import AssemblyModal from "../../../../ss-dev/slide57/AssemblyModal";
import Asset from "../../../../ss-dev/slide45/Asset";

const stepsData = [
  { id: 0, label: "Derivative Material Creation without translation" },
  { id: 1, label: "Equivalent Claims Identified" },
  { id: 2, label: "Reusable text generated" },
  { id: 3, label: "Components generated" },
  { id: 4, label: "Derivative material creation using elements (without translation)" },
];

export const LocalisationWithoutTranslationLoaders = () => {
  const [openedModalId, setOpenedModalId] = useState(0);

  return (
    <>
      <StepWiseProgress steps={stepsData} viewButtons={true} setOpenedModalId={setOpenedModalId} />
      <ClaimIdentificationLocalisation open={openedModalId === 1} setOpen={setOpenedModalId} />
      <NonClaimIdentificationLocalisation open={openedModalId === 2} setOpen={setOpenedModalId} />
      <ImageModal open={openedModalId === 3} setOpen={setOpenedModalId} />
      {/* <AssemblyModal open={openedModalId === 4} setOpen={setOpenedModalId} /> */}
      <Asset open={openedModalId === 4} setOpen={setOpenedModalId} />
    </>
  );
};
