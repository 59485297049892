// import "./style.css";
import React, { useEffect, useState } from "react";
import { CustomSelect } from "../CustomSelect";
import filterIcon from "../assets/filter_list.svg";
import { Button, Col, Divider, Modal, Row, Skeleton } from "antd";
import { NonClaimIdentificationWithouthTranslation } from "../Slide31/NonClaimIdentificationWithoutTranslation";
import { NonClaimIdentificationWithouthTranslationDark } from "../Slide31Dark/NonClaimIdentificationDark";
import { getAssetElements } from "../../services/apiMethods/commonMethods";
import { getTranslatedElements } from "../../services/apiMethods/translation";

interface Claim {
  id: string;
  type: string;
  text: string;
}

export const NonClaimIdentificationLocalisationWithTranslation: React.FC<{
  open: boolean;
  setOpen: Function;
}> = ({ open, setOpen }) => {
  //const [visible, setVisible] = useState(true);
  const handleCancel = () => setOpen(prev => !prev);
  const handleOk = () => setOpen(prev => !prev);

  const [nonClaims, setNonClaims] = useState<Claim[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [originalNonClaims, setOriginalNonClaims] = useState<Claim>();
  const [selectedItemIndices, setSelectedItemIndices] = useState<number[]>([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState<string>("All");
  const [orignalElementFiltered, setOrignalElementFiltered] = useState<Claim>();

  useEffect(() => {
    if (selectedTag !== "All") {
      //@ts-ignore
      const filteredData = originalNonClaims.filter(d => d.type === selectedTag);
      console.log("filteredData:", filteredData);
      setOrignalElementFiltered(filteredData);
    } else {
      setOrignalElementFiltered(originalNonClaims);
    }
  }, [selectedTag]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getTranslatedElements();
      if (res.status === 200) {
        setIsLoading(false);
        setNonClaims(res.data.data["non_claims"]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await getAssetElements();
      if (res.status === 200) {
        setOriginalNonClaims(res.data.data["non_claims"]);
        setOrignalElementFiltered(res.data.data["non_claims"]);
        const tagsSet = new Set();

        res?.data?.data["non_claims"]?.forEach(d => {
          tagsSet.add(d.type);
        });
        const tags = Array.from(tagsSet);
        console.log("tags:", tags);
        setTags(tags);
      }
    })();
  }, []);
  function mergeArrays(localised: any, original: any) {
    const len = original?.length;
    const newArr = [];
    for (let i = 0; i < len; i++) {
      const obj = {};
      obj["original"] = original[i];
      obj["localised"] = localised[i];
      newArr.push(obj);
    }
    return newArr;
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedTag(value);
    // You can perform any action here based on the selected value
  };

  const newArr = mergeArrays(nonClaims, orignalElementFiltered);
  return (
    <Modal
      className="fullscreen-modal"
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button style={{ marginRight: "3rem" }} key="submit" onClick={handleOk} type="primary">
          Save
        </Button>,
      ]}
      open={open}
      onCancel={handleCancel}
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>Content Creation: </b>
          <span style={{ fontWeight: "lighter" }}>
            Equivalent Reusable Text Identification with Translation
          </span>
        </span>
      }
    >
      <Row
        gutter={[10, 10]}
        style={{
          borderRadius: ".5rem",
          padding: ".5rem",
          width: "100%",
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "full" }}>
          <Col xs={24} sm={12}>
            <CustomSelect
              w="20rem"
              optionsList={["All"]}
              placeholder="Select"
              onChange={handleChange}
              icon={<img src={filterIcon} />}
              tagsDropDown={tags}
              selectedTag={selectedTag}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col style={{ flex: "1" }}>
            <b>
              Original Element{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                {/* (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>) */}
              </span>
            </b>
          </Col>
          <Col style={{ flex: "1" }}>
            <b>
              Equivalent Elements{" "}
              <span style={{ fontWeight: 400, color: "gray", fontSize: "14px" }}>
                (Market : <span style={{ fontWeight: 500, color: "black" }}>US</span>)
              </span>
            </b>
          </Col>
        </Row>
        <Skeleton loading={isLoading} paragraph={{ rows: 12 }}>
          <>
            {newArr.map((item: any, index: number) => {
              return (
                <>
                  <Row gutter={[16, 16]} style={{ width: "100%" }}>
                    <Col style={{ flex: "1" }} key={item.id}>
                      <NonClaimIdentificationWithouthTranslation
                        key={item.id}
                        data={item.original}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>

                    <Col style={{ flex: "1" }}>
                      <NonClaimIdentificationWithouthTranslationDark
                        key={item.id}
                        data={item.localised}
                        setSelectedItemIndices={setSelectedItemIndices}
                        Index={index}
                        selectedItemIndices={selectedItemIndices}
                      />
                    </Col>
                  </Row>
                  <Divider />
                </>
              );
            })}
          </>
        </Skeleton>
      </Row>
    </Modal>
  );
};
