export const LoadingOverlay: React.FC = () => {
  return (
    <div style={overlayStyle}>
      <div style={spinnerStyle}>Loading...</div>
    </div>
  );
};

const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const spinnerStyle: React.CSSProperties = {
  fontSize: "24px",
  color: "#fff",
};
