import { useState } from "react";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import { ElementIdentificationModal } from "../../../../ma-dev/ElementIdentificationModal";

const stepLabels = [
  "Material Element Identified",
  "Material Deconstruction",
  "Claims Identified",
  "Reusable Text Identified",
  "Components Identified",
];

const stepsData = stepLabels.map((label, index) => ({
  label,
}));

export const AssetElementIdentifiedLoaders = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StepWiseProgress
        steps={stepsData}
        viewAllButton={true}
        onViewAllClick={() => setOpen(true)}
      />
      <ElementIdentificationModal open={open} setOpen={setOpen} />
    </>
  );
};
