import { useState } from "react";
import { StepWiseProgress } from "../../components/Conversation/Loaders/StepWiseProgress";
import Component from "../../../../ss-dev/slide55/PersonalisationModal";
import LocalisedClaimModal from "../../../../ss-dev/slide52/LocalisedClaimModal";
import LocalisedNonClaimModal from "../../../../ss-dev/slide52/LocalisedNonClaimModal";
import AssemblyModal from "../../../../ss-dev/slide57/AssemblyModal";

const stepsData = [
  { id: 0, label: "Derivative Material Creation with personalization (without translation)" },
  { id: 1, label: "Equivalent Claims Personalised" },
  { id: 2, label: "Personalised reusable text generated" },
  { id: 3, label: "Personalised components generated" },
  {
    id: 4,
    label: "Personalized derivative material creation with personalization (without translation)",
  },
];

export const PersonalisationWithoutTranslationLocalisation = () => {
  const [openedModalId, setOpenedModalId] = useState(0);

  return (
    <>
      <StepWiseProgress steps={stepsData} viewButtons={true} setOpenedModalId={setOpenedModalId} />
      <LocalisedClaimModal open={openedModalId === 1} setOpen={setOpenedModalId} />
      <LocalisedNonClaimModal open={openedModalId === 2} setOpen={setOpenedModalId} />
      {/* Images */}
      <Component open={openedModalId === 3} setOpen={setOpenedModalId} />
      <AssemblyModal open={openedModalId === 4} setOpen={setOpenedModalId} />
    </>
  );
};
