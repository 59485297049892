import React, { useEffect, useMemo } from "react";
import { Col, Modal, Row, Tabs, TabsProps, Image, Button, Skeleton } from "antd";

import { CustomSelect } from "../CustomSelect";
import filterIcon from "../assets/filter_list.svg";
import { ClaimIdentificationWithouthTranslation } from "../Slide31";
import { useState } from "react";
import { ImageCardCollapse } from "../Slide25ImageCard";
// import "./style.css";
import { getTranslateElements } from "../../services/apiMethods/elementIdentification";
import { NonClaimIdentificationWithouthTranslation } from "../Slide31/NonClaimIdentificationWithoutTranslation";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFViewer } from "../../pages/Content Replicator/components/pdf/PDFViewer";
import { useSourcePDFURL } from "../../pages/Content Replicator/hooks/useSourcePDFURL";
import { useAppSelector } from "../../store/hooks";
import { annotatedPDFURLs } from "./mockData";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface Claim {
  id: string;
  name: string;
  key_message: string;
  reference: string[];
  footnote: string[];
  language: string;
}

interface NonClaim {
  id: string;
  type: string;
  text: string;
}

interface Image {
  promomats_id: string;
  url: string;
  tag: string;
  image_type: string;
}

export const ElementIdentificationModal: React.FC<{
  tabName?: string;
  open: boolean;
  setOpen: Function;
}> = ({ tabName = "Claims", open, setOpen }) => {
  const { selectedAssetParameters } = useAppSelector(state => state.contentReplicator);
  // const [visible, setVisible] = useState(true);
  const [claims, setClaims] = useState<Claim[]>([]);
  const [nonClaims, setNonClaims] = useState<NonClaim[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState({});
  const [activeTabKey, setActiveTabKey] = useState<number | string>(1);
  const handleCancel = () => setOpen(prev => !prev);
  const handleOk = () => setOpen(prev => !prev);

  const sourcePDFURL = useSourcePDFURL();

  const tagsList: { name: string; value: string }[] = [
    {
      name: "Market",
      value: data["market"] || "-",
    },
    {
      name: "Language",
      value: data["language"],
    },
    {
      name: "Brand",
      value: data["brand"],
    },
    {
      name: "Material Type",
      value: data["asset_type"],
    },
  ];
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getTranslateElements();
      if (res.status === 200) {
        setIsLoading(false);
        setData(res.data.data);
        setClaims(res.data.data["claims"]);
        setImages(res.data.data["images"]);
        setNonClaims(res.data.data["non_claims"]);
      }
    })();
  }, []);
  const getIdLists = (key, list) =>
    list?.map(ele => ele[key]).filter((value, index, array) => array.indexOf(value) === index);
  const claimIdLists = getIdLists("id", claims);
  const nonClaimIdLists = getIdLists("id", nonClaims);
  const imgIds = getIdLists("promomats _id", images);
  const claimTagLists = getIdLists("key_message", claims);
  const nonClaimTagLists = getIdLists("type", nonClaims);
  const imgTags = getIdLists("tag", images);
  const updatedClaimsList = useMemo(
    // () => (searchQuery ? claims?.filter(c => c.id === searchQuery) : claims),
    () => (searchQuery ? claims?.filter(c => c.key_message === searchQuery) : claims),

    [searchQuery, claims],
  );
  const updatedNonClaimsList = useMemo(
    // () => (searchQuery ? nonClaims?.filter(c => c.id === searchQuery) : nonClaims),
    () => (searchQuery ? nonClaims?.filter(c => c.type === searchQuery) : nonClaims),
    [searchQuery, nonClaims],
  );
  const updatedImageList = searchQuery
    ? // ? images?.filter(c => c["promomats _id"] === searchQuery)
      images?.filter(c => c["tag"] === searchQuery)
    : images;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Claims",
      children: (
        <>
          <Skeleton loading={isLoading} paragraph={{ rows: 10 }}>
            <Row style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
              <CustomSelect
                placeholder="Search and Select"
                w="30%"
                icon={<img src={filterIcon} />}
                optionsList={claimTagLists} //{claimIdLists}
                onChange={val => setSearchQuery(val)}
              />
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  md={12}
                  style={{ display: "flex", gap: "2rem", flexDirection: "column" }}
                >
                  {updatedClaimsList.map(c => {
                    return <ClaimIdentificationWithouthTranslation key={c.id} data={c} />;
                  })}
                </Col>
                <Col xs={24} md={12}>
                  <div
                    style={{
                      height: "600px",
                      overflow: "auto",
                      backgroundColor: "#E8EAF0",
                      padding: "16px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* <Document
                      file={{
                        url:
                          claims["url"] ||
                          "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
                      }}
                    >
                      <Page pageNumber={1} />
                    </Document> */}
                    <PDFViewer url={data["url"] || sourcePDFURL} />
                  </div>
                </Col>
              </Row>
            </Row>
          </Skeleton>
        </>
      ),
    },
    {
      key: "2",
      label: "Reusable Text",
      children: (
        <>
          <Skeleton loading={isLoading}>
            <Row style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
              <CustomSelect
                placeholder="Search and Select"
                w="30%"
                icon={<img src={filterIcon} />}
                optionsList={nonClaimTagLists} //{nonClaimIdLists}
                onChange={val => setSearchQuery(val)}
              />
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  md={12}
                  style={{ display: "flex", gap: "2rem", flexDirection: "column" }}
                >
                  {updatedNonClaimsList.map(item => {
                    return <NonClaimIdentificationWithouthTranslation key={item.id} data={item} />;
                  })}
                </Col>
                <Col xs={24} md={12}>
                  <div
                    style={{
                      height: "600px",
                      overflow: "auto",
                      backgroundColor: "#E8EAF0",
                      padding: "16px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/*                  <Document
                      file={{
                        url:
                          claims["url"] ||
                          "https://devkraftgenai.s3.ap-south-1.amazonaws.com/replicator/static/RTE_US_English.pdf",
                      }}
                    >
                      <Page pageNumber={1} />
                    </Document> */}
                    <PDFViewer url={data["url"] || sourcePDFURL} />
                  </div>
                </Col>
              </Row>
            </Row>
          </Skeleton>
        </>
      ),
    },
    {
      key: "3",
      label: "Components",
      children: (
        <>
          <Row style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
            <CustomSelect
              placeholder="Search and Select"
              w="30%"
              icon={<img src={filterIcon} />}
              optionsList={imgTags} //{imgIds}
              onChange={val => setSearchQuery(val)}
            />
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12} lg={15}>
                <Row gutter={[16, 16]}>
                  {updatedImageList?.map(img => {
                    const id = img["promomats _id"];
                    return (
                      <Col xs={24} sm={12} key={id}>
                        <ImageCardCollapse tag={img.tag} id={id} imgUrl={img?.url || ""} />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
              <Col xs={24} md={12} lg={9}>
                <div
                  style={{
                    height: "600px",
                    overflow: "auto",
                    backgroundColor: "#E8EAF0",
                    padding: "16px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <PDFViewer
                    url={claims["url"] || annotatedPDFURLs[selectedAssetParameters["brand"]]}
                  />
                </div>
              </Col>
            </Row>
          </Row>
        </>
      ),
    },
  ];
  const getTabName = (tabKey: any) => {
    if (tabKey == 1) {
      return "Claims";
    }
    if (tabKey == 2) {
      return "Non Claims";
    }
    if (tabKey == 3) {
      return "Image and Graphical Components";
    }
  };
  const currentTabName = getTabName(activeTabKey);
  return (
    <Modal
      className="fullscreen-modal"
      bodyStyle={{
        overflowY: "auto",
        padding: "1rem",
      }}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" onClick={handleOk} type="primary">
          Save
        </Button>,
      ]}
      open={open}
      onCancel={handleCancel}
      title={
        <span style={{ fontSize: "large", paddingLeft: ".7rem" }}>
          <b>Element Identification </b>
          {/* <span style={{ fontWeight: "lighter" }}>{currentTabName}</span> */}
        </span>
      }
    >
      <Row
        gutter={[10, 10]}
        style={{
          border: "1px solid lightGray",
          borderRadius: ".5rem",
          padding: ".5rem",
          // width: "100%",
        }}
      >
        {tagsList?.map(({ name, value }) => {
          return (
            <Col xs={24} sm={12} md={8} lg={6} style={{ paddingTop: "0" }} key={name + value}>
              <b>{name}</b>
              <span style={{ display: "block" }}>{value}</span>
            </Col>
          );
        })}
      </Row>

      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={ak => {
          setActiveTabKey(ak);
          setSearchQuery("");
        }}
      />
    </Modal>
  );
};
