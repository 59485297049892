import React from "react";
import { Input, Card, Row, Col, Typography } from "antd";
import { AudioOutlined, SendOutlined } from "@ant-design/icons";
import FeatureCards from "./Cards";
import { ASSETS } from "../../assets/assetsSrcMap";
const { Title, Text } = Typography;

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const backgroundStyle: React.CSSProperties = {
  backgroundImage: `url('${ASSETS.WELCOME_SCREEN_HEADER_IMAGE}')`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  minHeight: "330px",
  padding: "50px",
};

const inputStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "600px",
  borderRadius: "10px",
  padding: "12px 16px",
  fontSize: "16px",
  boxShadow: "0 0 20px rgba(198, 193, 215, 0.3)",
  border: "10px solid #e6e1ff",
};

export default function WelcomeScreen() {
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        overflowX: "auto",
        paddingBottom: "42px",
      }}
    >
      <div style={backgroundStyle}>
        <Title level={2} style={{ color: "white", marginBottom: "8px" }}>
          Welcome Camillia!
        </Title>
        <Text style={{ color: "white", marginBottom: "24px", display: "block" }}>
          This is your one stop shop for Compliance Ready Materials
        </Text>

        <Input
          styles={{ input: { fontSize: "13px" } }}
          placeholder="What would you like to create today? You can try saying 'I want to create an material'"
          suffix={
            <>
              <AudioOutlined style={{ color: "rgba(0,0,0,.45)", fontSize: "18px" }} />
              <SendOutlined style={{ color: "#4F2AD0", fontSize: "18px" }} />
            </>
          }
          style={inputStyle}
        />
      </div>

      <div
        style={{
          marginTop: "-60px",
          gap: "10px",
          justifyContent: "center",
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          bottom: "37%",
          left: "50%",
          color: "black",
          marginBottom: "0",
          padding: "0 3px",
        }}
      >
        <FeatureCards />
      </div>

      {/* Footer */}
      <div
        style={{
          position: "fixed",
          bottom: "0px",
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "center",
          zIndex: 1000,
          backgroundColor: "white",
          width: "100%",
          padding: "10px 0",
        }}
      >
        <Text style={{ color: "black", opacity: 0.7 }}>Powered By NEXT AI</Text>
        <Text style={{ color: "black", opacity: 0.7, marginLeft: "16px" }}>
          © 2024 Indegene. All Rights Reserved.
        </Text>
      </div>
    </div>
  );
}
