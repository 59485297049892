import React, { useState } from "react";
import { Card, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import ROUTE_PATHS from "../../routes/routePaths";
import { ASSETS } from "../../assets/assetsSrcMap";
import "../Content Transcreation/Predis.css";
import { InitialSelectionModal } from "../Content Transcreation/InitialScreen/InitialSelectionModal";

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  index: number;
  onClick?: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, index, onClick }) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        background: "#fff",
        display: "flex",
        flexDirection: "row", // Changed to row to align icon and text horizontally
        alignItems: "center",
        cursor: "pointer ",
      }}
      onClick={() => {
        index === 3 && navigate(ROUTE_PATHS.CONTENT_REPLICATOR);
        // index === 3 && (window.location.href = "https://genai.indegene.com/next-gist"); //Brand IQ/Semantic Search
        index === 0 &&
          window.open("https://genai.indegene.com/next-gist", "_blank", "noopener, noreferrer"); //Brand IQ/Semantic Search

        index === 2 && onClick(); //PREDIS AI
      }}
    >
      <div
        style={{
          backgroundColor: "#F3E8FF",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 16px 14px 0",
        }}
      >
        {icon}
      </div>
      <div>
        <h3 style={{ marginBottom: "4px" }}>{title}</h3>
        <p style={{ color: "#8c8c8c", fontSize: "13px", margin: 0 }}>{description}</p>
      </div>
    </Card>
  );
};

/**
 * New Order:
 * Ideation Agent, Concept Agent, Net New Content Agent, Derivative Content Agent, PRC Agent, and Insights Agent
 */
const cardData = [
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_PSYCHOLOGY_INSIGHT_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Ideation Agent",
    description:
      "Generates actionable insights, competitive intelligence, innovative ideas, and beyond",
  },
  {
    icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Concept Agent",
    description: "One-stop solution for all creative needs",
  },
  {
    icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
    title: "Net New Content Agent",
    description: "Instructs to create compliant content",
  },
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_DYNAMIC_FEED_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Derivative Content Agent",
    description: "Effortlessly create derivative content across markets",
  },

  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_ACCOUNT_AVATAR_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "PRC Agent",
    description: "Expedites content approval while ensuring compliance",
  },
  {
    icon: (
      <img
        src={ASSETS.DASHBOARD_EMOJI_INSIGHT_ICON}
        style={{ fontSize: "24px", color: "#722ed1" }}
      />
    ),
    title: "Insights Agent",
    description: "Delivers tailored analytics with conversational insights",
  },
  // {
  //   icon: <img src={ASSETS.DASHBOARD_EDIT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
  //   title: "MLR Acceleration",
  //   description: "Expedites content approvals"
  // },
  // {
  //   icon: <img src={ASSETS.DASHBOARD_EMOJI_INSIGHT_ICON} style={{ fontSize: "24px", color: "#722ed1" }} />,
  //   title: "Gen AI Playground",
  //   description: "Explores Gen AI capabilities in a domain context"
  // }
];

const FeatureCards: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div style={{ padding: "24px 50px" }}>
      <Row gutter={[16, 16]} justify="start">
        {cardData.map((card, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={8}>
            <FeatureCard
              icon={card.icon}
              title={card.title}
              description={card.description}
              index={index}
              onClick={() => {
                setOpenModal(true);
              }}
            />
          </Col>
        ))}
        {openModal && <InitialSelectionModal open={openModal} setOpen={setOpenModal} />}
      </Row>
    </div>
  );
};

export default FeatureCards;
