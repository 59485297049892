import { Modal, List, Badge, Typography } from "antd";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import "./DescriptionModal.css";

const { Title } = Typography;

export const DescriptionModal = ({ open, setOpen }: { open: boolean; setOpen: Function }) => {
  const oncancel = () => {
    setOpen(false);
  };
  const onOk = () => {
    setOpen(false);
  };
  return (
    <Modal
      title="MLR Validation Score: 3/4"
      className="mlr-submission-description-modal"
      open={open}
      onCancel={oncancel}
      onOk={onOk}
      onClose={oncancel}
      okButtonProps={{ type: "primary" }} // Custom type for Ok button
    >
      <div style={{ padding: 10, display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          <Title level={5}>
            Passed <Badge count={3} style={{ backgroundColor: "#6f42c1", marginLeft: 8 }} />
          </Title>
          <hr />
          <List
            dataSource={[
              "Claims have references",
              "Data chart and graphics have references linked to claims",
              "There is a fair balance in Material content",
            ]}
            renderItem={item => (
              <List.Item>
                <CheckCircleFilled style={{ color: "green", marginRight: 8 }} />
                {item}
              </List.Item>
            )}
          />
        </div>

        <div>
          <Title level={5}>
            Warnings <Badge count={1} style={{ backgroundColor: "#6f42c1", marginLeft: 8 }} />
          </Title>
          <hr />

          <List
            dataSource={["Images used are not following the brand guidelines"]}
            renderItem={item => (
              <List.Item>
                <ExclamationCircleFilled style={{ color: "orange", marginRight: 8 }} />
                {item}
              </List.Item>
            )}
          />
        </div>

        <div>
          <Title level={5}>
            Errors
            <Badge count={0} showZero style={{ backgroundColor: "#6f42c1", marginLeft: 8 }} />
          </Title>
          <hr style={{ marginBottom: 30 }} />
        </div>
      </div>
    </Modal>
  );
};
