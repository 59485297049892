import { Button, notification, Row } from "antd";
import { SourceCard } from "./SourceCard";
import { useState } from "react";
import { LoadingOverlay } from "./LoadingOverlayScreen";

export const CT_FINAL_PRODUCTS = {
  VIDEO: "Video",
  SOCIAL_MEDIA_POST: "Social Media Post",
  BANNER: "Banner",
  JOURNAL_AD: "Journal Ad",
  LITERATURE: "Leave Behind Literature",
  POSTER: "Poster",
  NEWSLETTER: "Newsletter",
  BROCHURE: "Brochure",
};

export type CT_FINAL_PRODUCTS_LIST_ITEM_TYPE = {
  id: number;
  name: string;
  selected: boolean;
};

export const CT_FINAL_PRODUCTS_LIST: CT_FINAL_PRODUCTS_LIST_ITEM_TYPE[] = [];
Object.keys(CT_FINAL_PRODUCTS).forEach((key, index) =>
  CT_FINAL_PRODUCTS_LIST.push({
    id: index,
    name: CT_FINAL_PRODUCTS[key as keyof typeof CT_FINAL_PRODUCTS],
    selected: index === 0 ? true : false,
  }),
);

export const InitialScreen = ({ setModalOpen }: { setModalOpen: Function }) => {
  const [productList, setProductList] = useState(CT_FINAL_PRODUCTS_LIST);
  const [isLoading, setIsLoading] = useState(false);

  const selectedProduct = productList.find(item => item.selected);

  const openPredisAIPopup = () => {
    setIsLoading(true);
    //@ts-ignore
    const predis = new window.Predis();

    predis.initialize({
      appId: "2ac15VN8a3F3S0AGHNVI8cjV7mU4V99N",
      embedToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiIyYWMxNVZOOGEzRjNTMEFHSE5WSThjalY3bVU0Vjk5TiIsInVzZXJfaWQiOiJwcmF0ZWVrLmphaW5AaW5kZWdlbmUuY29tIiwiaWF0IjoxNzAxMTUxMDU3fQ.rDThcHWFyAlGfxoKAUPyRjVKJQ-nqWtw8Hwk11I4OmU",
      //@ts-ignore
      mode: window.Predis.modes.DETAILED,
    });

    predis.on("ready", () => {
      // open Predis.ai post creator
      console.log("Ready!");
      setIsLoading(false); // Stop showing the loading overlay

      activatePredisFullScreen();

      predis.createPost({
        onPostPublish: function (err: any, data: any) {
          if (err) {
            console.log("Error Occurred in Posting", err);
            notification.error({ message: "Error Occurred in Posting" });
            return;
          }
          notification.success({ message: "Post created successfully" });
          // published posts' data
          console.log({ data });
        },
      });
    });

    predis.on("error", (err: any) => {
      console.log({ err });
      notification.error({ message: "Error Occurred in Predis SDK Initialization" });
      // catch if there is any error in SDK initialization
      setIsLoading(false);
    });
  };

  const activatePredisFullScreen = () => {
    let timer = setInterval(() => {
      const elmParentDiv = document.getElementsByTagName("iframe")?.[0]?.parentElement;

      if (elmParentDiv) {
        elmParentDiv.className = "predis-dialog";
        clearInterval(timer);
      }
    }, 1000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div>
        <h2 style={{ marginBottom: "1rem" }}>What would you like to create today?</h2>
        <Row gutter={[16, 16]}>
          {productList.map((item, index) => (
            <SourceCard
              key={index}
              selected={item.selected}
              title={item.name}
              onClick={() => {
                setProductList(
                  productList.map((item, idx) => ({
                    ...item,
                    selected: idx === index,
                  })),
                );
              }}
            />
          ))}
        </Row>
      </div>
      <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
        <Button
          type="default"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (selectedProduct?.name === CT_FINAL_PRODUCTS.SOCIAL_MEDIA_POST) {
              console.log("Social Media Selected");
              openPredisAIPopup();
            } else setModalOpen(false);
          }}
        >
          Submit
        </Button>
      </div>
      {isLoading ? <LoadingOverlay /> : null}
    </div>
  );
};
